

<template>
  <sequential-entrance fromBottom>

    <v-flex xs1 class="d-flex justify-center">
      <v-btn rounded small @click="goBack()"
             color="#295099">
        <span class="flex center text-white"
        ><v-icon>mdi-arrow-left</v-icon> Back
        </span>
      </v-btn>
    </v-flex>

    <v-card elevation="1" class="col-md-12 mt-2">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <p>KEYS:</p>
          <v-icon color="primary">mdi-flag</v-icon> Pending
          <v-icon color="orange">mdi-flag</v-icon> On Progress
          <v-icon color="pink">mdi-flag</v-icon> On Hold
          <v-icon color="success">mdi-flag</v-icon> Completed
          <v-icon color="secondary">mdi-flag</v-icon> Delayed
        </div>
      </div>
    </v-card>

    <v-card elevation="1" class="col-md-12">
      <table>
        <template>
          <thead>
          <tr>
            <th colspan="35">JNHPP - JULIAS NYERERE HYDROPOWER PROJECT</th>
          </tr>
          <tr>
            <th rowspan="2"><strong>Activity Name</strong></th>
            <th rowspan="2"><strong>Duration (Months)</strong></th>
            <th rowspan="2"><strong>Start Date</strong></th>
            <th rowspan="2"><strong>End Date</strong></th>
            <th style="background-color:#9e6452" colspan="12"><strong>First Year</strong></th>
            <th style="background-color:#529e7d" colspan="12"><strong>Second Year</strong></th>
            <th colspan="2" rowspan="2"><strong>Status</strong></th>
            <th colspan="2" rowspan="2"><strong>Progress</strong></th>
          </tr>
          <tr>
            <th><strong>J</strong></th>
            <th><strong>F</strong></th>
            <th><strong>M</strong></th>
            <th><strong>A</strong></th>
            <th><strong>M</strong></th>
            <th><strong>J </strong></th>
            <th><strong>J</strong></th>
            <th><strong>A</strong></th>
            <th><strong>S</strong></th>
            <th><strong>O</strong></th>
            <th><strong>N</strong></th>
            <th><strong>D</strong></th>

            <th ><strong>J</strong></th>
            <th><strong>F</strong></th>
            <th><strong>M</strong></th>
            <th><strong>A</strong></th>
            <th><strong>M</strong></th>
            <th><strong>J </strong></th>
            <th><strong>J</strong></th>
            <th><strong>A</strong></th>
            <th><strong>S</strong></th>
            <th><strong>O</strong></th>
            <th><strong>N</strong></th>
            <th><strong>D</strong></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in reportData" :key="item.name">
            <td>{{ item.name }}</td>
            <td>
              {{ item.duration }}
            </td>

            <td >{{ item.startDate }}</td>
            <td>{{ item.endDate }}</td>

            <td :class="getClass(`${item.class1[0]}`)"></td>
            <td :class="getClass(`${item.class1[1]}`)"></td>
            <td :class="getClass(`${item.class1[2]}`)"></td>
            <td :class="getClass(`${item.class1[3]}`)"></td>

            <td :class="getClass(`${item.class1[4]}`)"></td>
            <td :class="getClass(`${item.class1[5]}`)"></td>
            <td :class="getClass(`${item.class1[6]}`)"></td>
            <td :class="getClass(`${item.class1[7]}`)"></td>

            <td :class="getClass(`${item.class1[8]}`)"></td>
            <td :class="getClass(`${item.class1[9]}`)"></td>
            <td :class="getClass(`${item.class1[10]}`)"></td>
            <td :class="getClass(`${item.class1[11]}`)"></td>

            <td  :class="getClass(`${item.class2[0]}`)"></td>
            <td  :class="getClass(`${item.class2[1]}`)"></td>
            <td  :class="getClass(`${item.class2[2]}`)"></td>
            <td  :class="getClass(`${item.class2[3]}`)"></td>

            <td  :class="getClass(`${item.class2[4]}`)"></td>
            <td  :class="getClass(`${item.class2[5]}`)"></td>
            <td  :class="getClass(`${item.class2[6]}`)"></td>
            <td  :class="getClass(`${item.class2[7]}`)"></td>

            <td  :class="getClass(`${item.class2[8]}`)"></td>
            <td  :class="getClass(`${item.class2[9]}`)"></td>
            <td  :class="getClass(`${item.class2[10]}`)"></td>
            <td  :class="getClass(`${item.class2[11]}`)"></td>


            <td colspan="2">
              <template>
                <v-icon :color="item.status">mdi-flag</v-icon>
              </template>
            </td>
            <td>
                <span>
                  <v-progress-linear

                    :color="
                      item.progress> 60
                        ? 'green'
                        : item.progress < 45
                        ? 'orange'
                        : 'blue'
                    "
                    v-model="item.progress"
                    :buffer-value="100"
                    height="20"
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </span>
            </td>
          </tr>
          </tbody>
        </template>
      </table>
    </v-card>
  </sequential-entrance>
</template>

<script setup>

export default {
  name: "ProjectGanttChart",
  data() {
    return {
      reportData: [
        {
          name: "Digital Ecosystem",
          duration: 5,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2023",
          progress: 34,
          class1:['red','red','red','red','red','red','red','red','red','red','red','red'],
          class2:['red','red','red','red','red','white','white','white','white','white','white','white'],
          status:'primary'
        },
        {
          name: "Digital Platform & Service",
          duration: 6,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2025",
          progress: 69,
          class1:['white','white','white','white','red','red','red','red','red','red','red','red'],
          class2:['red','red','red','red','red','red','white','white','white','white','white','white'],
          status:'primary'
        },
        {
          name: "Digital Connectivity",
          duration: 2,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2023",
          progress: 49,
          class1:['white','white','white','white','red','red','red','red','red','red','red','red'],
          class2:['white','white','white','white','red','red','red','red','red','red','red','red'],
          status:'primary'
        },
        {
          name: "Digital Ecosystem",
          duration: 5,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2023",
          progress: 10,
          class1:['white','white','white','white','white','white','white','red','red','red','red','red'],
          class2:['white','white','white','white','white','white','white','red','red','red','red','red'],
          status:'primary'
        },
        {
          name: "Digital Platform & Service",
          duration: 6,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2025",
          progress: 69,
          class1:['white','white','white','white','red','red','red','red','red','red','red','red'],
          class2:['red','red','red','red','red','red','red','red','red','red','red','red'],
          status:'orange'
        },
        {
          name: "Digital Connectivity",
          duration: 2,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2023",
          progress: 49,
          class1:['white','white','white','white','white','white','white','white','white','white','white','white'],
          class2:['red','red','red','red','red','red','red','white','white','white','white','white'],
          status:'orange'
        },
        {
          name: "Digital Ecosystem",
          duration: 5,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2023",
          progress: 34,
          class1:['white','white','white','white','white','white','white','white','white','red','red','red'],
          class2:['white','white','white','white','white','white','white','white','white','white','white','white'],
          status:'success'
        },
        {
          name: "Digital Platform & Service",
          duration: 6,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2025",
          progress: 69,
          class1:['white','white','white','white','white','white','white','white','white','red','red','red'],
          class2:['red','red','red','red','red','red','red','red','red','red','red','red'],
          status:'pink'
        },
        {
          name: "Digital Connectivity",
          duration: 2,
          startDate: "Jan 4, 2022",
          endDate: "Dec 4, 2023",
          progress: 49,
          class1:['white','white','white','white','white','white','white','white','white','white','white','red'],
          class2:['red','red','red','red','red','red','red','red','red','red','red','red'],
          status:'success'
        },
      ],
    }
  },
  methods: {
    goBack() {
      return this.$router.back();
    },
    getClass(a) {
      if (a === "white") {
        this.class = "first"
        return this.class
      }
      if (a === "red") {
        this.class = "second"
        return this.class
      } else {
        this.class = "third"
        return this.class
      }
    }
  }
}

</script>

<style scoped>
.chart {
  height: 400px;
}

table {
  border-collapse: collapse;
  width: 100%;
  padding: 10px;
}


th {
  text-align: center;
  padding: 5px;
  border-top: none;
  border: 0.2px solid  var(--dtp-font-dark);
}
td {
  text-align: left;
  padding: 5px;
  border-top: none;
  border: 1px solid var(--dtp-font-dark);
}

.first{
  background-color: rgb(255, 255, 255)
}
.second {
  background-color: rgb(8, 82, 131)
}
.third {
  background-color: green
}
</style>
